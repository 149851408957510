/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

import '../bundles/application/javascripts';
import '../bundles/application/images';
import '../bundles/application/stylesheets';
import '../bundles/application/javascripts/controllers';

import ahoy from "ahoy.js";
import "@hotwired/turbo-rails";

Turbo.session.drive = false;
ahoy.trackClicks("a");
// ahoy.trackView();
//require('turbolinks').start();


// document.addEventListener('turbolinks:load', function () {
//   ReactOnRails.reactOnRailsPageLoaded();
// });
