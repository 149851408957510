import '@fortawesome/fontawesome-free/js/all';
import 'bootstrap3/dist/js/bootstrap';
import 'popper.js/dist/popper';
import 'magnific-popup/src/js/core';
import 'bootstrap-datepicker';
import imagesLoaded from 'imagesloaded/imagesloaded';

imagesLoaded.makeJQueryPlugin($);

import './findbob_front_end_custom.js';
import './controllers/index.js';
