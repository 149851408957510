import Masonry from 'masonry-layout/dist/masonry.pkgd';
import ScrollReveal from 'scrollreveal';
import ahoy from 'ahoy.js';
//import popper from 'popper.js/dist/popper.js';

import 'bootstrap-select/js/bootstrap-select';
window.scrollReveal = ScrollReveal;
document.ahoy = ahoy;
ahoy.trackClicks("a");
ahoy.trackView();
//window.popper = popper;
$(document).ready(function($) {
    'use strict';

    $('.nav > li > ul li > ul').css('left', $('.nav > li > ul').width());

    var navigationLi = $('.nav > li');
    navigationLi.hover(function() {
        if ($('body').hasClass('navigation-fixed-bottom')) {
            if ($(window).width() > 768) {
                var spaceUnderNavigation = $(window).height() - ($(this).offset().top - $(window).scrollTop());
                if (spaceUnderNavigation < $(this).children('.child-navigation').height()) {
                    $(this).children('.child-navigation').addClass('position-bottom');
                } else {
                    $(this).children('.child-navigation').removeClass('position-bottom');
                }
            }
        }
    });

    setNavigationPosition();

    // $('.tool-tip').tooltip();

    // var select = $('select');
    // if (select.length > 0) {
    //     select.selectpicker();
    // }

    // var bootstrapSelect = $('.bootstrap-select');
    // var dropDownMenu = $('.dropdown-menu');

    // bootstrapSelect.on('shown.bs.dropdown', function() {
    //     dropDownMenu.removeClass('animation-fade-out');
    //     dropDownMenu.addClass('animation-fade-in');
    // });

    // bootstrapSelect.on('hide.bs.dropdown', function() {
    //     dropDownMenu.removeClass('animation-fade-in');
    //     dropDownMenu.addClass('animation-fade-out');
    // });

    // bootstrapSelect.on('hidden.bs.dropdown', function() {
    //     var _this = $(this);
    //     $(_this).addClass('open');
    //     setTimeout(function() {
    //         $(_this).removeClass('open');
    //     }, 100);
    // });

    // select.change(function() {
    //     if ($(this).val() != '') {
    //         $('.form-search .bootstrap-select.open').addClass('selected-option-check');
    //     } else {
    //         $('.form-search  .bootstrap-select.open').removeClass('selected-option-check');
    //     }
    // });

    //  Contact form

    $('#form-contact-submit').bind('click', function(event) {
        $('#form-contact').validate({
            submitHandler: function() {
                $.post('assets/php/contact.php', $('#form-contact').serialize(), function(response) {
                    $('#form-status').html(response);
                    $('#form-contact-submit').attr('disabled', 'true');
                });
                return false;
            },
        });
    });
    
    //  Price slider

    var $priceSlider = $('#price-input');
    if ($priceSlider.length > 0) {
        $priceSlider.slider({
            from: 1000,
            to: 299000,
            step: 1000,
            round: 1,
            format: {
                format: '$ ###,###',
                locale: 'en',
            },
        });
    }

    //  Parallax scrolling and fixed header after scroll

    $('#map .marker-style').css('opacity', '.5 !important');
    $('#map .marker-style').css('bakground-color', 'red');

    $(window).scroll(function() {
        var scrollAmount = $(window).scrollTop() / 1.5;
        scrollAmount = Math.round(scrollAmount);
        if ($('body').hasClass('navigation-fixed-bottom')) {
            if ($(window).scrollTop() > $(window).height() - $('.navigation').height()) {
                $('.navigation').addClass('navigation-fix-to-top');
            } else {
                $('.navigation').removeClass('navigation-fix-to-top');
            }
        }

        if ($(window).width() > 768) {
            if ($('#map').hasClass('has-parallax')) {
                //$('#map .gm-style > div:first-child > div:first-child').css('margin-top', scrollAmount + 'px'); // old script
                $('#map .gm-style').css('margin-top', scrollAmount + 'px');
                $('#map .leaflet-map-pane').css('margin-top', scrollAmount + 'px');
            }
            if ($('#slider').hasClass('has-parallax')) {
                $('.homepage-slider').css('top', scrollAmount + 'px');
            }
        }
    });

    //  Masonry grid listing

    if ($('.property').hasClass('masonry')) {
        var container = $('.grid');
        var elem = document.querySelector('.grid');
        container.imagesLoaded(function() {
            new Masonry(elem, {
                gutter: 15,
                itemSelector: '.masonry',
            });
        });
        if ($(window).width() > 991) {
            $('.masonry').hover(
                function() {
                    $('.masonry').each(function() {
                        $('.masonry').addClass('masonry-hide-other');
                        $(this).removeClass('masonry-show');
                    });
                    $(this).addClass('masonry-show');
                },
                function() {
                    $('.masonry').each(function() {
                        $('.masonry').removeClass('masonry-hide-other');
                    });
                }
            );

            var config = {
                after: '0s',
                enter: 'bottom',
                move: '20px',
                over: '.5s',
                easing: 'ease-out',
                viewportFactor: 0.33,
                reset: false,
                init: true,
            };
            window.scrollReveal = new scrollReveal(config);
        }
    }

    //  Pricing Tables in Submit page

    if ($('.submit-pricing').length > 0) {
        $('.btn').click(function() {
            $('.submit-pricing .buttons td').each(function() {
                $(this).removeClass('package-selected');
            });
            $(this).parent().css('opacity', '1');
            $(this).parent().addClass('package-selected');
        });
    }

    centerSearchBox();

    try{
        $('.litebox-concierge').magnificPopup({
            type: 'iframe',
        });
    }catch(e){

    }
    
});

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// On RESIZE
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

$(window).on('resize', function() {
    setNavigationPosition();
});

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// On LOAD
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

$(window).on(function() {
    //  Show All button

    showAllButton();

    var $number = $('.number');
    if ($number.length > 0) {
        $number.waypoint(
            function() {
                initCounter();
            }, {
                offset: '100%',
            }
        );
    }

    agentState();
});

// Set height of the map

$(window).on('resize', function() {
    setMapHeight();
});

function setMapHeight() {
    var $body = $('body');
    if ($body.hasClass('has-fullscreen-map')) {
        $('#map').height($(window).height() - $('.navigation').height());
    }
    $('#map').height($(window).height() - $('.navigation').height());
    var mapHeight = $('#map').height();
    var contentHeight = $('.search-box').height();
    var top;
    top = mapHeight / 2 - contentHeight / 2;
    if (!$('body').hasClass('horizontal-search-float')) {
        $('.search-box-wrapper').css('top', top);
    }
    if ($(window).width() < 768) {
        $('#map').height($(window).height() - $('.navigation').height());
    }
}

function setNavigationPosition() {
    $('.nav > li').each(function() {
        if ($(this).hasClass('has-child')) {
            var fullNavigationWidth =
                $(this).children('.child-navigation').width() +
                $(this).children('.child-navigation').children('li').children('.child-navigation').width();
            if ($(this).children('.child-navigation').offset().left + fullNavigationWidth > $(window).width()) {
                $(this).children('.child-navigation').addClass('navigation-to-left');
            }
        }
    });
}

function showAllButton() {
    if ($('#pricing')) {
        $('#pricing').hide();
    }
    let $layoutExpandable = $('.layout-expandable');

    $('.show-all').on('click', function() {
        if ($layoutExpandable.hasClass('layout-expanded')) {
            $layoutExpandable.removeClass('layout-expanded');
            $('.show-all').removeClass('layout-expanded');
            $('#pricing').hide();
        } else {
            $layoutExpandable.addClass('layout-expanded');
            $('.show-all').addClass('layout-expanded');
            $('#pricing').show();
        }
    });
}

//  Center Search box Vertically

function centerSearchBox() {
    var $searchBox = $('.search-box-wrapper');
    var $navigation = $('.navigation.container');
    var positionFromBottom = 20;
    if ($('body').hasClass('navigation-fixed-top')) {
        $('#map, #slider').css('margin-top', $navigation.height());
        $searchBox.css('z-index', 98);
    } else {
        $('.leaflet-map-pane').css('top', -50);
        $('.homepage-slider').css('margin-top', -$('.navigation header').height());
    }
    if ($(window).width() > 768) {
        $('#slider .slide .overlay').css('margin-bottom', $navigation.height());
        $('#map, #slider').each(function() {
            if (!$('body').hasClass('horizontal-search-float')) {
                var mapHeight = $(this).height();
                var contentHeight = $('.search-box').height();
                var top;
                if ($('body').hasClass('has-fullscreen-map')) {
                    top = mapHeight / 2 - contentHeight / 2;
                } else {
                    top = mapHeight / 2 - contentHeight / 2 + $('.navigation').height();
                }
                $('.search-box-wrapper').css('top', top);
            } else {
                $searchBox.css('top', $(this).height() + $navigation.height() - $searchBox.height() - positionFromBottom);
                $('#slider .slide .overlay').css(
                    'margin-bottom',
                    $navigation.height() + $searchBox.height() + positionFromBottom
                );
                if ($('body').hasClass('has-fullscreen-map')) {
                    $('.search-box-wrapper').css('top', $(this).height() - $('.navigation').height());
                }
            }
        });
    }
}

$(document).ready(function() {
    const pover = $('[data-toggle="popover"]');
    if (pover) {
        try {
            pover.popover({
                trigger: 'hover',
                placement: 'bottom',
                html: true,
            });
            if ($('.show-all')) {
                showAllButton();
            }
        } catch(e) {

        }
    }
    $('.modal').on('shown.bs.modal', function(e) {
        $('.welcome-slider').resize();
    });

    if (window.noInterest) {
        const noInterestModal = $('#NoInterestModal');
        noInterestModal.modal({
            backdrop: 'static',
            keyboard: false,
            show: true,
        });
    }

    if (window.noContinuity) {
        const noContinuityModal = $('#NoContinuityModal');
        noContinuityModal.modal({
            backdrop: 'static',
            keyboard: false,
            show: true,
        });
    }
    if ($('#flash')) {
        setTimeout(function() {
            $('#flash').slideUp(500);
        }, 10000);
    }
});

$(document).on('click', '#messageButton', function() {
    var option = $(this).data('id');
    var title = $(this).data('title');
    var noteCount = parseInt($(this).data('notecount'));
    $('.gen-case').html(title);
    $('.myId').html(option);
    if (noteCount < 1) {
        $('.rolodexCB').show();
    } else {
        $('.rolodexCB').hide();
    }
});